import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import spaContext from 'utils/spaContext';
import accountService from 'services/accountService';
import {
  appMetadata,
  getRequestedAppName,
  redirectOutOfSPA,
  LANDING_PAGE_PATH,
  SIGNIN,
  SIGNIN_PATH,
  SIGNIN_PATH_ROOT
} from 'services/application';
import loggerUtil from 'utils/logger';
import { DevApp } from 'components/types';
import configureAxios from 'utils/axiosDefaults';
import { inIFrame, notifyUnauthorized } from 'utils/iframe';
import { getPathname, locationRedirect, getWindowPathWithHash } from 'utils/Location.util';
import Shell from '../Shell/Shell';

const logger = loggerUtil('Shell.container');

/**
 * @param basePath Path to check
 * @returns {boolean} Whether window location starts with passed-in basePath. Case insensitive
 */
const windowPathStartsWith = basePath => {
  const path = getPathname().toLowerCase();

  return path.startsWith(basePath);
};

/**
 * @returns {object}
 * - A Shell containing the sign-in app if the user if the user is in the sign-in app path.
 * - A react router Redirect to the signin app otherwise.
 */
const getUnauthenticatedRenderAction = props => {
  // User is not signed in, send them to signin. Note: /login will redirect to /login/ too for consistency.
  const inSigninApp = windowPathStartsWith(SIGNIN_PATH);

  return (
    inSigninApp
      ? <Shell {...props} />
      : <Redirect to={SIGNIN_PATH} />
  );
};

/**
 * @returns {string} Name of the spa app at the given URL, or null if no spa app matches the url
 */
const getSpaAppNameAtUrl = redirectUrl => {
  // If a logged-in user goes directly to anypoint.mulesoft.com/signin, then this.redirectUrl will be undefined
  // Note: Logged-in users requesting login app will be shown the login app. That app has code that handles
  // determining whether to redirect those users to /home or to show them the requested form.
  // See https://github.com/mulesoft/anypoint-signin/blob/UIFX-723/src/components/index.js#L57.
  const signInRequested = !redirectUrl;
  if (signInRequested) {
    return SIGNIN;
  }
  return getRequestedAppName(redirectUrl);
};

/**
 * Handles authentication routing in the SPA.
 * - Checks to see if the user is authenticated and where they are trying to go.
 * - Load the user's desired application if the user is authenticated.
 * - Redirects the user to the signin page if the user is unauthenticated.
 */
class ShellContainer extends Component {

  static propTypes = {
    devApp: DevApp
  };

  static defaultProps = {
    devApp: null,
  };

  constructor(props, context) {
    super(props, context);

    // Set the axios defaults with a callback for unauthorized calls
    configureAxios(() => {
      // The user just made a request that was rejected, most likely because of session timeout.
      // They will be shown the login page, after which they should be returned back to the page
      // where they were doing their work.
      const spaApp = getSpaAppNameAtUrl(getPathname());
      if (spaApp !== SIGNIN) {
        // Obviously if we are already at login, we do not want to redirect back to login
        // after a successful login.
        this.redirectUrl = getPathname();

      }
      this.setProfileAndRerender();
    });

    // Set the SpaContext before child apps render
    spaContext.actions.onSignin = this.onSignin;
    this.state = {
      isAuthenticated: false,
      loadingProfile: true
    };

    const { devApp } = this.props;
    if (devApp) {
      const { path } = devApp;
      const appName = path.split('/')[1];
      // Overwrite the path so that the SPA knows how to route to the dev app.
      // This lets known SPA apps to point to the correct location when doing local development.
      appMetadata[appName] = {
        path
      };
    }
  }

  /**
   * Attempt to get the user profile. Use that attempt and the application the
   * user is attempting access to derive what application to take them to.
   */
  componentDidMount() {

    // The profile call succeeds if the user has a session cookie from logging in previously.
    // On page refreshes, we need to get both the accessToken and profile.
    accountService.getProfileWithAccessToken()
      .then(([profile, accessToken]) => {

        this.setProfileAndRerender(accessToken, profile);
      })
      .catch(e => {

        this.setProfileAndRerender(null, null);
      });
  }

  /**
   * Called by the signin app after the user successfully logs into the platform
   *
   * @param accessToken User's bearer token
   * @param coreServicesRedirectUrl Redirect URL sent to us from core services.
   */
  onSignin = (accessToken, coreServicesRedirectUrl) => {




    // We'll have an SPA redirectUrl if the user originally arrived at an SPA app.
    // If they did not, e.g. if they requested /exchange, then we'll use the redirect
    // url returned by core services' login endpoint.
    this.redirectUrl = this.redirectUrl || coreServicesRedirectUrl;

    const requestedSpaAppName = getSpaAppNameAtUrl(this.redirectUrl);

    // If the user requested a non-SPA app, then after signin we just send them there
    const shouldRedirectOutOfSPA = !requestedSpaAppName;
    if (shouldRedirectOutOfSPA) {
      redirectOutOfSPA(this.redirectUrl);
    } else {
      // On signin, we already have the access token from the /signin call.
      accountService.getProfileWithoutAccessToken()
        .then(profile => this.setProfileAndRerender(accessToken, profile))
        .catch(e => {

          // We assume the user is unauthenticated in this case and redirect to the login page.
          // TODO we should handle other exceptions here as well.
          this.setProfileAndRerender(null, null);
        });
    }
  };

  /**
   * Called when logout is clicked in the navbar.
   * Wipes out the profile, calling setProfileAndRerender to re-render and show the signin page.
   */
  onSignout = () => accountService.logout()
    .then((redirectUrl) => {

      const shouldRedirect = redirectUrl && window;
      if (shouldRedirect) {
        locationRedirect(redirectUrl);
      } else {
        this.setProfileAndRerender(null, null);
      }
    })
    .catch(error => {

      this.setProfileAndRerender(null, null);
    });

  /**
   * Sets the user profile to re-render the SPA with the proper context.
   * The profile can be null to signify a logged out / unauthenticated user.
   * @param accessToken - the user accessToken or null
   * @param profile - the user profile or null
   */
  setProfileAndRerender = (accessToken, profile) => {

    spaContext.accessToken          = accessToken;
    spaContext.profile              = profile;
    const isAuthenticated           = !!profile;
    const isUnAuthenticatedInIframe = !isAuthenticated && inIFrame();
    if (isUnAuthenticatedInIframe) {
      // If the user's session has expired, and the SPA is hosted in an iframe,
      // then we notify the parent window so it can handle the failure e.g. redirect to login page.
      notifyUnauthorized();
    } else {
      this.setState({
        isAuthenticated,
        loadingProfile: false
      });
    }
  };

  /**
   * @returns {object}
   *  - A Redirect to the requested SPA path if it is to an SPA app.
   *  - The Shell containing the SPA app if the user is already at the url path.
   *  - Null if no SPA app was requested.
   */
  getAuthenticatedRenderAction = () => {
    const requestedPath       = this.redirectUrl || getWindowPathWithHash();
    const requestedSpaAppName = getSpaAppNameAtUrl(this.redirectUrl);





    if (requestedSpaAppName) {
      const currentSpaAppName = getSpaAppNameAtUrl(getPathname());

      // If a SPA app was requested and they are currently not there, <Redirect> them to it.
      // We have to redirect if we are not where the user originally wanted to be. If we render the Shell
      // w/o the redirect, the Shell's router renders the wrong route regardless what requestedSpaAppName is.
      const shouldRedirect = currentSpaAppName !== requestedSpaAppName && requestedPath !== getWindowPathWithHash();
      if (shouldRedirect) {

        return <Redirect to={requestedPath} />;
      }

      const showNavBar = currentSpaAppName !== SIGNIN;
      // Clear the redirectUrl so a user signing in and out in the SPA doesn't get the previous redirectUrl.
      this.redirectUrl = null;
      // An organization change can change the set of permissions that a user has (this affects the Navbar's app switcher).
      // Fetching the profile retrieves the new permissions and causes a re-render of the app.
      return (
        <Shell
          {...this.props}
          showNavBar={showNavBar}
          onSignout={this.onSignout}
        />
      );
    }
    return null;
  };

  /**
   * Store where the user originally intended to go so that we can redirect back to it after logging in.
   * In the case of a non-shell app, the original pathname will always be /login.
   * We handle this directly in the render logic for clarity.
   */
  recordRedirectUrl = () => {
    this.redirectUrl = getWindowPathWithHash();

    if (this.redirectUrl === '/') {

      this.redirectUrl = LANDING_PAGE_PATH;
    }
  };

  /**
   * Determine where the user is trying to go and if they are authenticated.
   * Based on that information determine what app to send the user to.
   * We then route to the proper app. This might trigger a explicit redirect.
   *
   * Landing page serves as a fallback if we can't figure out where to send them.
   *
   * Note about signin flow: For SPA apps we will redirect to /signin without reloading the page,
   * after which we want to go back to the requested SPA app. We record the requested SPA app path
   * using recordRedirectUrl().
   *
   * For non-SPA apps, however, some go through the OAuth dance properly, and so after login, we get a
   * redirectUrl back from core services which we will obey and reload the page with. Some will not,
   * though, and core services will return /home/, which we also have to obey.
   *
   * TODO: If we use document.referer, can we solve the redirect problem for non-SPA apps too?
   * Note about NGINX: NGINX is configured to direct /login requests to the SPA.
   * As we add new apps, for example /b2b or /home, then those paths will also point to the SPA.
   * This means that the requested app name will ALWAYS be a known SPA path since
   * the SPA only renders for requests to SPA apps!
   *
   * If the app that was requested could not be found, default to the landing page,
   * which is Anypoint's 404 page.
   */
  render() {
    // Every time the shell loads, it will immediately try to load the user's profile.
    // We cannot know what to render until that completes.
    const { loadingProfile, isAuthenticated } = this.state;

    if (loadingProfile) {
      // We don't record pathname if the user requested /login*: We don't want to overwrite
      // the original redirectUrl that was there if a user was redirected to /login
      //
      // Signin app handles all /login related urls. See anypoint-signin's history.push call:
      //  https://github.com/mulesoft/anypoint-signin/blob/UIFX-723/src/components/index.js
      const pathStartsWithLogin = windowPathStartsWith(SIGNIN_PATH_ROOT);
      if (!pathStartsWithLogin) {
        this.recordRedirectUrl();
      }
      return null;
    }

    const componentToRender = isAuthenticated
      ? this.getAuthenticatedRenderAction()
      : getUnauthenticatedRenderAction(this.props);

    // If there is nothing to render, then a non-SPA app was requested
    if (!componentToRender) {
      redirectOutOfSPA(this.redirectUrl);
    }

    return componentToRender;
  }
}

export default ShellContainer;

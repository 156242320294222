import typedLocalStorage from 'utils/typedLocalStorage';
import loggerFactory from 'utils/logger';

const logger = loggerFactory('Profile');

const createUserSettingsKey = (userId) => {
  if (!userId) {
    return null;
  }

  return `userSettings.${userId}.spa`;
};

/**
 * e.g.
 * {
 *   organizationSettings: {
 *     [orgID]: {
 *       activeEnvId: [envId]
 *     }
 *   }
 * }
 */
const createLocalSettings = () => ({
  organizationSettings: {}
});

/**
 * @param userId - the user id of the local settings you want to get
 */
const getLocalSettings = userId => {
  const userSettingsKey = createUserSettingsKey(userId);
  return typedLocalStorage.getObject(userSettingsKey) || createLocalSettings();
};

/**
 * @param {String} userId - the user id of the local settings you want to save
 * @param {JSON} localSettings - the local settings you want to save
 */
const saveLocalSettings = (userId, localSettings) => {
  const userSettingsKey = createUserSettingsKey(userId);
  typedLocalStorage.setObject(userSettingsKey, localSettings);
};

/**
 * The Profile wraps the auth server's profile object with methods to get user information (e.g. activeOrgId, activeEnvId).
 * Calls should not be called if the profile doesn't exist. If it does it's a bug (e.g. logged out user, or done before profile call).
 */
export default class Profile {

  /**
   * @param profileData - the profile response from auth server /profile call
   */
  constructor(profileData) {
    Object.assign(this, profileData);
  }

  /**
   * @returns the active org if it exists, the main org if it doesn't exist, null if the profile doesn't exist.
   */
  getActiveOrgId = () => {
    const activeOrgExists = this.properties && this.properties.cs_auth;
    if (activeOrgExists) {
      const activeOrg = this.contributorOfOrganizations
        .find(org => org.id === this.properties.cs_auth.activeOrganizationId);
      if (activeOrg) {
        return activeOrg.id;
      }
    }
    // Return the main org id if the org does not exist.
    return (this.organization && this.organization.id) || null;
  };

  /**
   * Verifies that the user is still a member of the org.
   */
  orgExists = orgId => this.contributorOfOrganizations.some(org => org.id === orgId);

  /**
   * @return {string|null} - the user's default environment id for the activeOrg
   * or null if the profile doesn't exist / there's no default environment set.
   */
  getDefaultEnvId = () => {
    const orgId = this.getActiveOrgId();
    const orgPreferences = this.organizationPreferences && this.organizationPreferences[orgId];
    return orgPreferences ? orgPreferences.defaultEnvironment : null;
  };

  /**
   * @returns {Promise <string | null>} - the activeEnvId of the current user's activeOrg.
   * If activeEnvId doesn't exist, the defaultEnvId.
   * If defaultEnvId doesn't exist, the first environment of the current activeOrg.
   * Returns null if all cases fail.
   */
  getActiveEnvId = environments => {
    const activeOrgId = this.getActiveOrgId();
    const userSettings = getLocalSettings(this.id);

    const orgSettings = userSettings.organizationSettings[activeOrgId];
    const storedActiveEnvId = orgSettings && orgSettings.activeEnvId;


    if (storedActiveEnvId) {
      const envExists = environments && environments.some(env => env.id === storedActiveEnvId);
      if (envExists) {

        return storedActiveEnvId;
      }
    }

    const replacementEnv = this.getDefaultEnvId()
    || (Array.isArray(environments)
      && environments.length
      && environments[0].id)
    || null;


    return replacementEnv;
  };

  /**
   * Saves the activeEnvId for the active organization of the current user.
   */
  setActiveEnvId = envId => {
    const activeOrgId = this.getActiveOrgId();
    const userSettings = getLocalSettings(this.id);
    userSettings.organizationSettings[activeOrgId] = { activeEnvId: envId };

    saveLocalSettings(this.id, userSettings);
  };

}

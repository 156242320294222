/**
 * @returns true if the SPA is in an iFrame, false otherwise.
 */
export const inIFrame = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

/**
 * Internal utility function to ensure that all postMessage calls happen
 * with a prescribed prefix and use window.location.origin as the only
 * allowed target for messages.
 */
const postMessage = (messageType) => {
  window.parent.postMessage(`SPA.${messageType}`, window.location.origin);
};

/*
 * Only call this method if you are in an iframe.
 * Sends a message to the parent to notify that the session is unauthorized.
 */
export const notifyUnauthorized = () => {
  postMessage('Unauthorized');
};

/*
 * Only call this method if you are in an iframe.
 * Sends a message to the parent to notify that the user profile has been updated and should be refetched.
 */
export const notifyProfileUpdated = () => {
  postMessage('ProfileUpdated');
};

// Private methods
const localStorage = () => {
  if (window && window.localStorage) {
    return window.localStorage;
  }

  return {
    /* eslint-disable no-console */
    setItem: () => console.warn('Cannot set item: no local storage available'),
    getItem: () => console.warn('Cannot get item: no local storage available'),
    /* eslint-enable no-console */
  };
};

/**
 * Handles the getting and setting of local storage.
 * Wraps the browsers native functionality in type friendly calls.
 *
 * IMPORTANT: This class has a programmed mock in the __mocks__ folder
 */
const typedLocalStorage = {

  /**
   * Put a string into local storage.
   */
  setString: (key, value) => localStorage().setItem(key, value),

  /**
   * Get a string from local storage.
   */
  getString: key => localStorage().getItem(key),

  /**
   * Put a number into local storage.
   */
  setNumber: (key, value) => localStorage().setItem(key, parseInt(value, 10)),

  /**
   * Get a number from local storage. Will return NaN if the value is
   * falsy or cannot be parsed.
   */
  getNumber: key => parseInt(localStorage().getItem(key), 10),

  /**
   * Transform the value into a string respresentation and then store it in
   * local storage.
   * @param {String} key
   * @param {JSON} value
   */
  setObject: (key, value) => {
    try {
      let sanitizedValue = value;
      if (typeof value === 'string') {
        // We still want it as a string, but we check first if it's parseable
        sanitizedValue = JSON.stringify(JSON.parse(value));
      }
      if (typeof value === 'object') {
        sanitizedValue = JSON.stringify(value);
      }
      localStorage().setItem(key, sanitizedValue);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Cannot parse local storage value with:', value);
    }
  },

  /**
   * Get the string representation of the value for the key from local storage
   * and then revert the transformation turning it back into an object.
   *
   * @param key
   */
  getObject: (key) => {
    try {
      let value = localStorage().getItem(key);
      value = JSON.parse(value);
      return value;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Cannot parse local storage value with key '${key}'`);
      return false;
    }
  },
};

export default typedLocalStorage;

/**
 * Helper that uses window.location object to avoid using it directly
 */

/**
 * Alias for Window Assign
 * @param {string} redirectUrl url to be redirected to
 */
export function locationRedirect(redirectUrl) {
  window.location.assign(redirectUrl);
}

export function getPathname() {
  return window.location.pathname;
}

export function getWindowPathWithHash() {
  const { pathname, hash } = window.location;
  return pathname + hash;
}

// Note: we use the /shared/anypoint-navbar from index.html in prod
import NavBar from '@mulesoft/anypoint-navbar'; // eslint-disable-line import/no-extraneous-dependencies
import AppLink from './AppLink';

/**
 * Default context for the SPA. We declare this as a singleton so
 * that the same instance of the context is used by all SPA components.
 *
 * Normally, something like this would be done via a React context or redux.
 * But, for simplicity, for now, we use a singleton.
 */
const spaContext = {
  /** The user's access token. Used to make API requests on the user's behalf. */
  accessToken: undefined,
  /** The orgId of the user's current organization. */
  activeOrganizationId: undefined,
  /** The envId of the user's current environment. */
  activeEnvironmentId: undefined,
  /**
   * @returns {boolean|undefined} Whether the current environment is running Anypoint Private Cloud Edition,
   */
  isOnPrem: () => {
    try {
      return window.Anypoint.deployment === 'PCE';
    } catch (e) {
      return false;
    }
  },
  /** A SPA-provided axios instance with accessToken and 401 handling pre-configured. */
  axios: undefined,
  /** Components that the spaContext exposes for special interaction with the SPA e.g. Links */
  components: {
    AppLink,
  },
  /**
   * The current authenticated user's profile using the SPA Profile class.
   * The Profile has methods for getting the current activeOrgId and current activeEnvId.
   * Profile is null if unauthenticated.
   */
  profile: undefined,
  /** The path prefix for the current app. */
  mountPath: undefined,
  /** A list of environments for the currently active org defined in the profile. */
  environments: undefined,
  /** Provides a set of actions to the other apps so they may trigger functionality. */
  actions: {
    /**
     * Should be called any time a user signs in, it is overridden by the shell
     * with a full implementation.
     *
     * @param newAuthToken The user's authentication token.
     * @param redirectUrl Where to send the user to after they sign in.
     */
    onSignin: (newAuthToken, redirectUrl) => { // eslint-disable-line no-unused-vars
      throw new Error('onSignin is not implemented');
    },
    /**
     * Should be called any time any information is updated that would affect the user
     * profile.
     */
    onUpdatedProfileData: () => {
      throw new Error('onUpdatedProfileData is not implemented');
    }
  },
  /** Exposes a set of services from Navbar for SPA apps to use. */
  services: {
    analyticsServiceFactory: NavBar.analyticsServiceFactory,
    launchDarklyFactory:     NavBar.launchDarklyFactory,
    productServiceFactory:   NavBar.productServiceFactory,
    recentItemsService:      NavBar.recentItemsService,
    toastService:            NavBar.toastService,
  },
  /** Provides callbacks to change the sidebar. */
  sidebar: {
    /** The currently active sidebar link */
    activeSidebarLink: null,
    /** The callback to set the initial sidebar */
    initialize: null,
    /** The callback to set the sidebar section */
    setSectionConfig: null
  }
};

// Ensure that all properties must be defined above. No new properties can be added to the spaContext.
// By sealing the spaContext, this file becomes the single source of truth for what the spaContext contains.
Object.seal(spaContext);

export default spaContext;

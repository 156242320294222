// Special file that is only included in the bundle on build. See the readme for details.
import React      from 'react';
import { render } from 'react-dom';

// Import for anypoint-styles to be compiled into the build.
import '@mulesoft/anypoint-styles';

// The import pollyfills Promise as it's side effect.
// https://github.com/stefanpenner/es6-promise#auto-polyfill
import 'es6-promise/auto';
import { MainApp } from 'components';
import loggerUtil, { LOG_LEVEL_ALL } from 'utils/logger';
import './index.css';

// For local SPA development, I set log level to ALL. SPA apps will, however,
// see only error logging by default.
if (window) {
  window.SPA_LOG_LEVEL = LOG_LEVEL_ALL;
}

const logger = loggerUtil('index');

const mainAppContainer = document.getElementById('shellRoot');
render(<MainApp />, mainAppContainer);

import { getAppManifest } from 'services/application';
import loggerUtil from 'utils/logger';

const logger = loggerUtil('ApplicationFactory');

// Save a reference to the App Name to remove previous JS & CSS bundles before switching apps.
let spaAppName;

export const createJsTagId = appName => `${appName}Js`;
export const createCssTagId = appName => `${appName}Css`;
export const removeNodeWithId = id => {
  const node = document.getElementById(id);
  if (node) {
    node.parentNode.removeChild(node);
  }
};

/**
 * Builds an application using a dom element for holding the scripts and an app name.
 */
class ApplicationFactory {
  /**
   * Configures the container to inject all scripts into.
   *
   * @param scriptsContainerId The id of the <div> element to load scripts/tags into;
   */
  constructor(scriptsContainerId) {
    if (!scriptsContainerId) {
      throw new Error('A container id must be provided');
    }
    this.scriptsContainerId = scriptsContainerId;
  }

  /**
   * Sets the context for a build function that can be used to construct the app.
   *
   * @param appName - The app being loaded. appName must match an app in the manifest.
   *
   * @returns {function(): Promise} - A build function that:
   * (1) loads the application's manifest
   * (2) loads the app into the DOM
   * (3) returns a context object with the react app component and its manifest.
   */
  loadApp = appName => async () => {
    const manifest = await getAppManifest(appName);
    const scriptsContainer = document.getElementById(this.scriptsContainerId);
    const app = ApplicationFactory.build(manifest, scriptsContainer);

    return {
      app,
      manifest
    };
  };

  /**
   * Injects application's css and js bundles into the script container.
   *
   * @param manifest - The applications manifest
   * @param scriptsContainer - Dom element to put the scripts in
   *
   * @returns {Promise} - Promise that resolves with the React app component when the javascript
   * script element finishes loading.
   *
   * TODO: support apps with chunks (multiple JS and CSS files)
   */
  static build(manifest, scriptsContainer) {
    if (spaAppName) {
      // Before building, remove the previous app JS and CSS bundle so they don't affect the current app for app switching.

      const jsBundleId = createJsTagId(spaAppName);
      const csBundleId = createCssTagId(spaAppName);
      removeNodeWithId(jsBundleId);
      removeNodeWithId(csBundleId);
    }
    spaAppName = manifest.name;


    ApplicationFactory.addLinkTag(
      spaAppName,
      manifest[`${spaAppName}.css`],
      scriptsContainer,
    );

    return ApplicationFactory.addScriptTag(
      spaAppName,
      manifest[`${spaAppName}.js`],
      scriptsContainer,
    );
  }

  /**
   * Inject a script tag into the given HTML DOM container object
   *
   * @param appName - The name of the application being loaded.
   * @param fileUrl - The url for the javascript to inject
   * @param scriptsContainer - The dom element to inject the script tag into.
   *
   * @returns {Promise} - A promise that resolves with the app component when
   * the script is loaded.
   */
  static addScriptTag(appName, fileUrl, scriptsContainer) {
    const scriptTagId = createJsTagId(appName);
    const hasScriptTag = !!document.getElementById(scriptTagId);

    // Because each application has a different scriptTagId, the presence
    // of a scriptTag indicates that the app was already loaded.
    if (hasScriptTag) {
      const appComponent = window[appName].default;
      return Promise.resolve(appComponent);
    }

    const scriptTag = document.createElement('script');
    scriptTag.id    = scriptTagId;
    scriptTag.src   = fileUrl;

    // async downloads the file during HTML parsing and will pause the HTML parser
    // ONLY when the script has finished downloading (to execute it)
    // See http://www.growingwiththeweb.com/2014/02/async-vs-defer-attributes.html
    scriptTag.async = true;

    return new Promise((resolve, reject) => {
      scriptTag.onload = () => {
        const appComponent = window[appName].default;
        return resolve(appComponent);
      };
      scriptTag.onerror = (e) => {
        // eslint-disable-next-line no-console
        console.error('There was an error loading the applications script');
        return reject(e);
      };

      // Script elements inserted using innerHTML won't be executed after being inserted.
      // See https://www.w3.org/TR/2008/WD-html5-20080610/dom.html#innerhtml0
      scriptsContainer.appendChild(scriptTag);
    });
  }

  /**
   * Inject a css link tag into the given HTML DOM container object
   *
   * @param appName - Name of the application the link belongs to.
   * @param fileUrl - The url for the css file to load
   * @param scriptsContainer - The dom element where the script tag is injected.
   */
  static addLinkTag(appName, fileUrl, scriptsContainer) {
    const tagId = createCssTagId(appName);
    let linkTag = document.getElementById(tagId);

    if (linkTag) {
      return;
    }

    linkTag = document.createElement('link');
    linkTag.id = tagId;
    linkTag.rel = 'stylesheet';
    linkTag.href = fileUrl;
    scriptsContainer.appendChild(linkTag);
  }
}

export default ApplicationFactory;

/**
 * Utility functions for URIs
 * URIs may be encoded, as seen below. However, links passed by users of the SPA can potentially pass in decoded links.
 * Before doing path comparisons, we decode both user passed links and the current path to ensure we compare links correctly.
 * Example:
 * /destinations/us-east-1/manage-queue/hi1434-.ji%E5%9C%A8%E6%B0%91%E5%AE%BF%E5%96%9D/settings
 * Example Decoded:
 * /destinations/us-east-1/manage-queue/hi1434-.ji在民宿喝/settings
 */

/**
 * @return {string} The current decoded pathname + hash fragment
 */
export const getCurrentPath = () => {
  const currentPath = `${window.location.pathname}${window.location.hash}`;
  return decodeURIComponent(currentPath);
};

/**
 * @param path {string} - a given URI path
 * @param link {string} - a given link
 * @return {boolean} - true if the URI path startsWith or equals the path link.
 */
export const doesPathMatchLink = (path, link) => {
  const decodedPath = decodeURIComponent(path);
  const decodedLink = decodeURIComponent(link);
  return !!decodedPath.startsWith(decodedLink) || (decodedPath === decodedLink);
};

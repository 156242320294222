import React, { Component } from 'react';
import PropTypes from 'prop-types';
import spaContext  from 'utils/spaContext';
import loggerUtil from 'utils/logger';
import ShellUI from 'components/ShellUI/ShellUI';
import styles from './styles.css';

const logger = loggerUtil('ApplicationLoader');

/**
 * The ApplicationLoader is responsible for loading the Shell UI with the SPA app determined from the url (e.g. /mq/).
 */
class ApplicationLoader extends Component {

  static propTypes = {
    /* Returns a promise that resolves with the root react component of the SPA app. */
    loadApp: PropTypes.func.isRequired,
    /* The path for the app e.g. /mq/ */
    appPath: PropTypes.string.isRequired,
    /* React router history passed to ShellUI */
    history: PropTypes.shape({}).isRequired,
    /* React router location passed to ShellUI */
    location: PropTypes.shape({}).isRequired,
    /* Determine if the Navbar needs to be shown (e.g. false for signin, true for most apps) */
    showNavBar: PropTypes.bool.isRequired,
    /* Callback for when the user signs out */
    onSignout: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      App: null,
      manifest: null,
      error: false,
    };
  }

  /**
   * Example Manifest Response:
   *  {
   *   "name": "testapp",
   *   "showOrgSwitcher": true,
   *   "showEnvSwitcher": false,
   *   "testapp.css": "/app/styles.testapp.css",
   *   "testapp.js": "/app/lib.testapp.js"
   *  }
   */
  componentDidMount() {
    this.props.loadApp()
      .then(({ app, manifest }) => {
        this.setState({
          App: app,
          manifest,
          error: false
        });
      })
      .catch(e  => this.setState({ App: null, error: e }));
  }

  render() {
    const {
      appPath,
      history,
      location,
      onSignout,
      showNavBar
    } = this.props;
    const { App, error, manifest } = this.state;

    if (App) {
      const { showOrgSwitcher, showEnvSwitcher } = manifest;
      return (
        <ShellUI
          appPath={appPath}
          app={App}
          history={history}
          location={location}
          showNavBar={showNavBar}
          showOrgSwitcher={!!showOrgSwitcher}
          showEnvSwitcher={!!showEnvSwitcher}
          spaContext={spaContext}
          onSignout={onSignout}
        />
      );
    }
    return error
      ? <div className={styles.error}>Could not load application: {error.message}</div>
      : null; // Can potentially render a loading screen here.
  }
}

export default ApplicationLoader;

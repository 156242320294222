import axios from 'axios';
import Profile from 'utils/Profile';
import NavBar from '@mulesoft/anypoint-navbar'; // eslint-disable-line import/no-extraneous-dependencies
import loggerUtil from 'utils/logger';
import constants from 'utils/constants';

const logger = loggerUtil('accountService');

const SPA_CLIENT_ID = 'anypoint_spa';

const loginService = NavBar.loginServiceFactory(axios);

const loginServiceOptions = {
  // https://github.com/mulesoft/anypoint-roles-clients/pull/310
  client_id: SPA_CLIENT_ID,
  // note: a redirect_url is unnecessary here since the client is configured to default to
  // https://{env.}anypoint.mulesoft.com/shared/silentAuthCallback.html.
};

/**
 * Fetches the user profile from Core Services using the session cookie for authorization.
 *
 * @returns Promise<Profile> Resolves with a {@link Profile} instance without the access_token property.
 */
const getProfileWithoutAccessToken = () => loginService
  .getProfileWithoutAccessToken()
  .then(profile => new Profile(profile));

/**
 * @returns function<Promise<accessToken>> function that resolves with the current user's accessToken.
 */
const getAccessToken = () => loginService.getAccessToken(loginServiceOptions);

/**
 * Note: We don't call loginService.getProfile() since it returns the profile with the accessToken in it.
 * We want to separate the profile from the accessToken since the accessToken doesn't belong in the profile
 * (i.e. The profile contains mostly static user information. The accessToken is for a single session only.)
 *
 * @returns Promise<Profile> Resolves with an object containing a {@link Profile} instance and a user accessToken.
 */
const getProfileWithAccessToken = () => loginService
  .getProfileWithoutAccessToken()
  .then(profile => Promise
    .all([new Profile(profile), getAccessToken()]));

/**
 * Logs the current user out.
 *
 * @returns Promise<object> Resolves with the logout response object's redirectUrl, if
 * present, otherwise with null.
 */
const logout = () => {
  const options = {
    method: 'get',
    credentials: 'same-origin'
  };
  return axios
    .get(constants.LOGOUT_URL, options)
    .then(response => response.data.redirectUrl);
};

/**
 * Called when a user selects an org from the NavBar organization switcher to update
 * the user's active org in the profile object sent by Core Services /profile call.
 *
 * @return Promise<Profile> - Changing the active organization causes the user's profile to have a set of permissions
 * for the new orgId. This means we have to re-fetch the profile to get the new set of app permissions.
 */
async function updateActiveOrganization(userId, organizationId) {

  await NavBar.updateActiveOrganization(userId, organizationId, axios);
  return getProfileWithoutAccessToken();
}

const accountService = {
  getAccessToken,
  getProfileWithoutAccessToken,
  getProfileWithAccessToken,
  logout,
  updateActiveOrganization
};

export default accountService;

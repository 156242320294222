/* eslint-disable no-console */

export const LOG_LEVEL_ALL   = 5;
export const LOG_LEVEL_ERROR = 1;

/**
 * Logger util function that acts as a wrapper around console.
 *
 * Note 1: These log lines are stripped out from production builds by Webpack using WebpackStrip.
 * Note 2: Disabled by default outside of browser, e.g. for tests, unless DEBUG env flag set to true
 * using "export DEBUG=true"
 *
 * @param context context for the log message. e.g. the name of the class or function where it's called.
 */
// Tests use jsdom-based window objects, and we only want a colored prefix in the browser
const isBrowser = !window.jsdom;
const env       = (typeof process !== 'undefined' && process && process.env) || {};

// For unit tests, this generates a lot of noise, so we make it dependent on DEBUG
const enabled     = isBrowser || (env.DEBUG === 'true');
const colors      = isBrowser ? ['color:#087299', 'color:black'] : []; // MuleSoft primary blue, black
const errorColors = isBrowser ? ['color:red', 'color:black'] : [];

const loggerUtil = (context) => {
  const contextPrefix = isBrowser ? `%c[${context}]%c` : context;
  // In the browser, we allow dynamically setting a log level so that spa app development
  // can be done with or without spa logging
  const logLevel = () => (isBrowser && window.SPA_LOG_LEVEL) || LOG_LEVEL_ERROR;
  return {
    log: (...text) => {
      if (enabled && logLevel() >= LOG_LEVEL_ALL) {
        console.log(contextPrefix, ...colors, '-', ...text);
      }
    },
    error: (...text) => {
      if (enabled && logLevel() >= LOG_LEVEL_ERROR) {
        console.error(contextPrefix, ...errorColors, '-', ...text);
      }
    }
  };
};

export default loggerUtil;

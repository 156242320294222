import { getRequestedAppName } from 'services/application';
import { Link } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Compute the precise component to use:
 * For SPA apps, we use a router Link
 * For old-school apps, an old school anchor
 * For the Management Center header, we just lay down a span.
 */
const getComponentForHref = (href, isSpaApp) => {
  if (isSpaApp) {
    return Link;
  }
  return href ? 'a' : 'span';
};

/**
 * A component used in SPA apps to create React Router links for links within
 * the SPA and anchor tags outside of the SPA to prevent full page reloads.
 * @param {Object} props
 * @param {string} props.href - The href of the link.
 * @return A React Router link for SPA apps | An anchor tag for non SPA apps.
 */
const AppLink = (props) => {
  const { href } = props;
  const isSpaApp = !!getRequestedAppName(href);
  const component = getComponentForHref(href, isSpaApp);
  const elementProps = {
    ...props,
  };
  // React Router Links have "to" and anchor tags have "href"
  if (isSpaApp) {
    elementProps.to = href;
    delete elementProps.href;
  }
  return React.createElement(component, elementProps);
};

AppLink.propTypes = {
  /** The link for the anchor or React Router link */
  href: PropTypes.string
};

AppLink.defaultProps = {
  // If the href is not defined (which is the case for the "Management Center" in the sidebar)
  // then the component will simply render
  href: null
};

export default AppLink;

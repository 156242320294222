import SidebarHeading from '@mulesoft/anypoint-components/raw/platform-components/SidebarHeading';
import SidebarGroup from '@mulesoft/anypoint-components/raw/platform-components/SidebarGroup';
import SidebarDivider from '@mulesoft/anypoint-components/raw/platform-components/SidebarDivider';
import SidebarLink from '@mulesoft/anypoint-components/raw/platform-components/SidebarLink';
import EnvSwitcher from '@mulesoft/anypoint-components/raw/platform-components/EnvSwitcher';
import React  from 'react';
import PropTypes from 'prop-types';
import loggerUtil from 'utils/logger';
import { getCurrentPath, doesPathMatchLink } from 'utils/uri';
import { SidePanel, Icon } from '@mulesoft/lume-components/dist/es5';
import { Link } from 'react-router-dom';
import styles from './Sidebar.container.styles.css';

if (!customElements.get('lume-side-panel')) {
  customElements.define('lume-side-panel', SidePanel.CustomElementConstructor);
}

if (!customElements.get('lume-icon')) {
  customElements.define('lume-icon', Icon.CustomElementConstructor);
}

const logger = loggerUtil('SidebarContainer');

/**
 * Maps a sidebar config to a sidebar component.
 * Example sidebar configuration with a heading, group of links, divider, and additional group of links:
  [
      {
        type: 'heading',
        icon: 'user-small',
        label: 'Sidebar Label Heading'
      },
      {
        type: 'group',
        children: [
          {
            href: 'client-apps/',
            label: 'Client Apps',
            icon: 'application',
            onClick: () => {},
            testId: '123'
          },
          {
            href: '#destinations/',
            label: 'Destinations'
            icon: 'destination'
          }
        ]
      },
      {
        type: 'divider'
      },
  ]
 */
const sideBarConfigToComponentMapper = {
  heading: (sidebarHeadingConfig, index) => {
    const { label, icon } = sidebarHeadingConfig;
    return (<SidebarHeading icon={icon} key={`${label}-${index}`} label={label} />);
  },
  // We support both absolute links (links starting with '/') and hash fragment links (links starting with '#')
  group: (sidebarGroupConfig, index, baseHref) => {
    const { children } = sidebarGroupConfig;
    const sideBarLinks = children
      .map(sidebarLinkConfig => {
        const {
          label,
          icon,
          href,
          onClick,
          testId
        } = sidebarLinkConfig;
        if (!href) {
          return null;
        }
        const isAbsoluteLink = href[0] === '/';
        const linkTo = href.startsWith(baseHref) ? href : `${baseHref}${href}`;
        // we assume absolute paths (e.g. /analytics) are intentional redirects to another SPA app
        // and will not prepend the mountPath for these links.
        const spaHref = isAbsoluteLink ? href : linkTo;
        const isActive = doesPathMatchLink(getCurrentPath(), spaHref);


        return (
          <SidebarLink
            href={spaHref}
            isActive={isActive}
            key={label}
            icon={icon}
            label={label}
            onClick={onClick}
            testId={testId}
          />
        );
      });
    return (
      <SidebarGroup key={children.map(link => link.label).join()}>
        {sideBarLinks}
      </SidebarGroup>
    );
  },
  divider: (sidebarDividerConfig, index) => (<SidebarDivider key={`${sidebarDividerConfig.type}-${index}`} />)
};

/**
 * Helper to transform sidebar config object to a list of sidebar components.
 */
const getSidebarComponents = (config, backHref) => config
  .map((sidebarConfigObj, index) => {
    const { type } = sidebarConfigObj;
    const sideBarMapperFcn = sideBarConfigToComponentMapper[type];
    return sideBarMapperFcn && sideBarMapperFcn(sidebarConfigObj, index, backHref);
  })
  .filter(sidebarComponent => !!sidebarComponent);

/**
 * @param links - the links of the current section
 * @param mountPath - the mountPath used to match the full path
 * Returns the active sidebar link, null if no links, throws if there are multiple active links.
 */
const getActiveSidebarLink = (links, mountPath) => {
  if (!Array.isArray(links)) {
    return null;
  }
  const activeSidebarLink = links.filter(sidebarLink => {
    const { href } = sidebarLink;
    const fullLink = href.startsWith(mountPath) ? href : `${mountPath}${sidebarLink.href}`;
    return doesPathMatchLink(getCurrentPath(), fullLink);
  });

  if (activeSidebarLink.length > 1) {
    throw new Error('You are not at a valid sidebar link. '
      + 'Sidebar links on the same level cannot be nested '
      + '(e.g. /destinations and /destinations/apps cannot be on the same sidebar).');
  }
  const isActiveSidebarLink = activeSidebarLink.length === 1;
  return isActiveSidebarLink ? activeSidebarLink[0] : null;
};

/**
 * Returns the sidebar link representing the active section or undefined if none are active.
 */
const getActiveSectionLink = sectionLinks => {
  if (!Array.isArray(sectionLinks)) {
    return null;
  }
  return sectionLinks.find(link => {
    const { href } = link;

    return doesPathMatchLink(getCurrentPath(), href);
  });
};

/**
 * Given a sidebar config object, returns a list of the sidebar link config objects.
 */
const getLinks = config => config && config
  .filter(sidebarItem => sidebarItem.type === 'group')
  .map(group => group.children)
  .reduce((acc, curr) => acc.concat(curr), []);

/**
 * The SidebarContainer maps a Sidebar configuration object to a sidebar and
 * handles changes to the EnvSwitcher.
 *
 * Notes:
 * - The SidebarContainer is not a PureComponent because it must update
 *   the sidebar activeLink based on the window.location, which isn't a prop or state change.
 *   (i.e. the sidebar config remains the same, the url just updated).
 * - The SidebarContainer will not render the sidebar if no sidebarConfig object is provided.
 * - For sidebar links:
 *   - we assume absolute paths (e.g. /mq) are requests for a redirect to another SPA app
 *     and will not prepend the mountPath for these links.
 *   - we assume all relative and hash hrefs (e.g. any link that doesn't begin with a '/')
 *     are links within the app and prepend the mountPath for these links.
 *   - we assume sidebar links & section links don't have to be nested /destinations/list, vs. /destinations/manage-queue.
 */
class SidebarContainer extends React.Component {

  static propTypes = {
    onEnvironmentChange: PropTypes.func.isRequired,
    sidebarConfig: PropTypes.arrayOf(PropTypes.object),
    sectionConfig: PropTypes.arrayOf(PropTypes.object),
    showEnvSwitcher: PropTypes.bool.isRequired,
    spaContext: PropTypes.shape({
      sidebar: PropTypes.shape({
        activeSidebarLink: PropTypes.shape({}),
      }),
      profile: PropTypes.shape({
        getActiveEnvId: PropTypes.func,
        getDefaultEnvId: PropTypes.func,
      }),
      axios: PropTypes.shape({}),
      actions: PropTypes.shape({}),
      environments: PropTypes.arrayOf(PropTypes.object),
      activeOrganizationIdions: PropTypes.shape({}),
      mountPath: PropTypes.string,
      activeOrganizationId: PropTypes.shape({}),
    }).isRequired,
    appName: PropTypes.string,
    appIcon: PropTypes.string,
  };

  static defaultProps = {
    sidebarConfig: null,
    sectionConfig: null,
    appName: '',
    appIcon: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      activeEnvId: null
    };
  }

  onActiveEnvChange = ({ value }) => {

    const { onEnvironmentChange } = this.props;
    onEnvironmentChange(value);
    this.setState({ activeEnvId: value });
  };

  renderEnvSwitcher() {
    const { spaContext, showEnvSwitcher } = this.props;
    const { activeEnvId } = this.state;
    const { profile, environments } = spaContext;
    const envExists = environments && environments.some(env => env.id === activeEnvId);
    const profileActiveEnvId = profile ? profile.getActiveEnvId(environments) : null;
    const activeEnv = envExists ? activeEnvId : profileActiveEnvId;

    return showEnvSwitcher && (
      <EnvSwitcher
        className={styles['env-switcher']}
        activeEnv={activeEnv}
        collection={environments}
        defaultEnv={profile.getDefaultEnvId()}
        onChange={this.onActiveEnvChange}
        profileUrl="/accounts/#/cs/profile/view"
      />
    );
  }

  // Copied directly from APC Sidebar as Lume doesn't yet have a component for the back button
  // eslint-disable-next-line class-methods-use-this
  renderBackLink = (backLabel, backHref) => {
    if (backLabel && backHref) {
      const isAbsoluteOrHashLink = backHref.indexOf('#') === 0 || backHref.indexOf('/') === 0;

      if (!isAbsoluteOrHashLink) {
        throw new Error('Sidebar Links must begin with either a "#" or "/" for correct navigation behavior');
      }

      // Hash routes do not reload the page, and so use anchor tags. Absolute routes need to use
      // react-router-dom Links so that they do not reload the page. Note that we assume that all
      // apps use react-router for routing.
      const backIcon = <lume-icon symbol="arrow-left" size="small" set="utility" class={styles['menu-back-icon']} />;
      const isAbsolutePath = backHref.indexOf('/') === 0;

      if (isAbsolutePath) {
        return (
          <Link className={styles['menu-back']} to={backHref}>
            { backIcon }
            { backLabel }
          </Link>
        );
      }

      return (
        <a className={styles['menu-back']} href={backHref}>
          { backIcon }
          { backLabel }
        </a>
      );
    }

    return null;
  };

  renderHeaderSlot = (backLabel, backHref) => {
    const envSwitcher = this.renderEnvSwitcher();
    const backLink = this.renderBackLink(backLabel, backHref);

    if (envSwitcher || backLink) {
      return (
        <div slot="header">
          { envSwitcher }
          { backLink }
        </div>
      );
    }

    return null;
  };

  renderSidebar = (backHref, backLabel, sidebarComponents, appName, appIcon) => (
    <lume-side-panel
      product-name={appName}
      product-icon={appIcon}
    >
      { this.renderHeaderSlot(backLabel, backHref) }
      <div>
        { sidebarComponents }
      </div>
    </lume-side-panel>
  );

  render() {
    const {
      spaContext,
      sidebarConfig,
      sectionConfig,
      appIcon,
      appName
    } = this.props;


    if (!sidebarConfig) {
      return null;
    }
    const sidebarLinks = getLinks(sidebarConfig);
    const activeSidebarLink = sidebarLinks && getActiveSidebarLink(sidebarLinks, spaContext.mountPath);

    if (activeSidebarLink) {

      spaContext.sidebar.activeSidebarLink = activeSidebarLink.href;

      const sectionLinks = getLinks(sectionConfig);
      const activeSectionLink = getActiveSectionLink(sectionLinks);


      if (activeSectionLink) {
        const backHref = `${spaContext.mountPath}${activeSidebarLink.href}`;
        const backLabel = activeSidebarLink.label;
        const sidebarComponents = getSidebarComponents(sectionConfig, backHref);
        return this.renderSidebar(backHref, backLabel, sidebarComponents, appName, appIcon);
      }
    }
    const sidebarComponents = getSidebarComponents(sidebarConfig, spaContext.mountPath);
    return this.renderSidebar(null, null, sidebarComponents, appName, appIcon);
  }
}

export default SidebarContainer;

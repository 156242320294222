import axios from 'axios';
import constants from 'utils/constants';
import loggerUtil from 'utils/logger';
import { locationRedirect } from 'utils/Location.util';

const logger = loggerUtil('application');

const TEST_APP = 'testapp';
const TEST_APP_PATH = `/${TEST_APP}/`;

/* Name for the signin application */
const SIGNIN = 'signin'; // This is the app name, as opposed to the url.
const SIGNIN_PATH_ROOT = '/login'; // Different from "signin" for historical reasons. sorry.
const SIGNIN_PATH = `${SIGNIN_PATH_ROOT}/`;

const MQ = 'mq';
const MQ_PATH = `/${MQ}/`;

/* Admin UI for Runtime fabric deployments */

const ARM_ADMIN_DEPLOYMENTS = 'admin-deployments';
const ARM_ADMIN_DEPLOYMENTS_PATH = `/${ARM_ADMIN_DEPLOYMENTS}/`;

const LANDING_PAGE = 'home';
const LANDING_PAGE_PATH = `/${LANDING_PAGE}/`;

const ACCOUNTS = 'accounts';
const ACCOUNTS_PATH = `/${ACCOUNTS}/`;

const ACCOUNTS_MIGRATION = 'accounts-spa';
const ACCOUNTS_MIGRATION_PATH = `/${ACCOUNTS_MIGRATION}/`;

const manifestFile = app => `/${app}/asset-manifest.json`;

/**
 * Key map of the metadata for each application.
 * All app paths must have the format /APP_NAME/
 *
 * @type {Object.<string, ApplicationMetadata>}
 */
const appMetadata = {
  [SIGNIN]: {
    path: SIGNIN_PATH,
    manifestPath: manifestFile(SIGNIN),
  },
  [TEST_APP]: {
    path: TEST_APP_PATH,
    manifestPath: manifestFile(TEST_APP),
  },
  [LANDING_PAGE]: {
    path: LANDING_PAGE_PATH,
    manifestPath: manifestFile(LANDING_PAGE)
  },
  [MQ]: {
    path: MQ_PATH,
    manifestPath: manifestFile(MQ)
  },
  [ACCOUNTS]: {
    path: ACCOUNTS_PATH,
    manifestPath: manifestFile(ACCOUNTS),
    shouldFallBackOnMissingManifest: true,
  },
  [ACCOUNTS_MIGRATION]: {
    path: ACCOUNTS_MIGRATION_PATH,
    manifestPath: manifestFile(ACCOUNTS_MIGRATION)
  },
  [ARM_ADMIN_DEPLOYMENTS]: {
    path: ARM_ADMIN_DEPLOYMENTS_PATH,
    manifestPath: manifestFile(ARM_ADMIN_DEPLOYMENTS)
  }
};

/**
 * Turns a window location object into an application name so it can be used by getAppMetadata.
 *
 * @param pathname - A window.location.pathname used to determine the applications name
 * @returns {string|null} - The application associated with the passed location,
 * or null if no such app is in appMetadata.
 */
const getRequestedAppName = pathname => {
  // Example path: https://devx.anypoint.mulesoft.com/login
  // Which is a window.location.pathname of "/login".
  if (!pathname) {
    return null;
  }
  // First, trim out the SPA root path, e.g. "/ui-shell/" or ""
  const trimmedPath = pathname.substr(constants.SPA_URL.length);
  const nameIndex = 1;
  // Split on "/" this produces ["", "login", ""], where "login" is the app name.
  const requestedAppName = trimmedPath.split('/')[nameIndex];
  // Remove hash fragment here, if present, since app names do not include hashes
  const cleanRequestedAppName = requestedAppName.split('#')[0];
  const appPath = `/${cleanRequestedAppName}/`;


  // IE doesn't support Array.find or findIndex... yeah...
  const appList = Object.keys(appMetadata);
  return appList.filter(
    appName => appMetadata[appName].path === appPath,
  )[0] || null;
};

/**
 * Returns a path for the passed appName. Throws an exception if there is no path for the given appName.
 *
 * @param appName - The application to get the path for.
 * @returns {string} - The path in the spa that corresponds to the name of the app
 */
const getAppSpaPath = (appName) => {
  const spaApp = appMetadata[appName];
  if (!spaApp) {
    throw new Error(`Invalid app, ${appName}`);
  }
  const { path } = spaApp;
  return `${constants.SPA_URL}${path}`;
};

/**
 * If non-SPA app was requested location.assign them to it. Note: originalPathname always overrides redirectUrl.
 * By default, we location.assign the user to /home/ (LANDING_PAGE_PATH).
 *
 * @param redirectUrl The url that user should be redirected to after logging in
 */
export const redirectOutOfSPA = redirectUrl => {

  const { pathname, hash } = window.location;
  const windowWithPathHash = pathname + hash;
  const redirectPath          = redirectUrl || LANDING_PAGE_PATH;

  const alreadyAtRedirectPath = windowWithPathHash === redirectPath;


  // For development, the SPA is at all paths, which means if the user is already logged in,
  // a request to '/' or '/someunknownapp' will loop infinitely. This check prevents that.
  if (!alreadyAtRedirectPath) {
    locationRedirect(redirectPath);
  }
};

/**
 * Returns the application metadata by making a get to the url associated with
 * the application name.
 *
 * @param appName Name of the anypoint application to get a manifest for
 * @returns {Promise<object>} Promise that resolves with the applications
 * manifest object
 */
const getAppManifest = (appName) => {
  const metadata = appMetadata[appName];
  if (!metadata) {
    return Promise.reject(new Error(`${appName} manifest not found`));
  }

  if (!metadata.manifestPath) {
    return Promise.reject(new Error(`${appName} manifest has no manifestPath`));
  }

  return axios
    .get(metadata.manifestPath)
    .then(response => {

      return response.data;
    })
    .catch(error => {
      logger.error('Unable to fetch app manifest', error);
      // For products that are onboarding to platform-ui & have not had their nginx configuration updated,
      // the manifest file will be missing
      // When this happens, we should break out of the history.push'd route and render the real thing
      if (metadata.shouldFallBackOnMissingManifest) {
        redirectOutOfSPA(metadata.path);
        // Return a promise that will never resolve so the callers of this function will
        // stay suspended until the page reloads
        return new Promise(() => {});
      }
      throw error;
    });
};

export {
  appMetadata,
  getAppManifest,
  getRequestedAppName,
  getAppSpaPath,
  SIGNIN,
  ACCOUNTS,
  SIGNIN_PATH,
  SIGNIN_PATH_ROOT,
  LANDING_PAGE,
  LANDING_PAGE_PATH,
  TEST_APP,
  TEST_APP_PATH,
  MQ,
  MQ_PATH
};

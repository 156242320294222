import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getAppSpaPath, appMetadata } from 'services/application';
import ApplicationFactory from 'utils/applicationFactory';
import { ApplicationLoader } from 'components';
import loggerUtil from 'utils/logger';
import styles from 'components/Shell/styles.css';
import { DevApp } from 'components/types';

const logger = loggerUtil('Shell');
const scriptsContainerId = 'appRootsScripts';

/**
 * The Shell defines the base React Router routes for SPA apps, e.g. /mq -> MQ app.
 * Each of these routes is configured to load and render the app using the ApplicationLoader.
 */
export default class Shell extends React.Component {

  static propTypes = {
    /** For local SPA app development. */
    devApp: DevApp,
    /* Whether the Navbar needs to be shown (e.g. false for signin, true for most apps) */
    showNavBar: PropTypes.bool,
    /* Called when the user signs out */
    onSignout: PropTypes.func,
  };

  static defaultProps = {
    devApp: null,
    showNavBar: false,
    onSignout: () => {},
  };

  /**
   * For each application in appMetadata (the list of known apps),
   * render a route that renders the app when the path matches.
   * e.g. If we're at /mq, renders the MQ app.
   *
   * @return {Array} - List of all possible app React Router Routes.
   */
  renderAppRoutes = () => {
    const { devApp } = this.props;
    const appRoutes = Object.keys(appMetadata);

    const appFactory = new ApplicationFactory(scriptsContainerId);
    return appRoutes.map(appName => {
      const appPath = getAppSpaPath(appName);
      const loadApp = appFactory.loadApp(appName);
      // If the request is to the SPA app that is currently being developed/tested,
      // then the SPA should use the devApp callback to load it
      const isDevAppPath = devApp && appPath === devApp.path;
      return (
        <Route
          key={appName}
          path={appPath}
          render={props => (
            <ApplicationLoader
              {...props}
              {...(this.props)}
              loadApp={isDevAppPath ? devApp.loader : loadApp}
              appPath={appPath}
            />
          )}
        />
      );
    });
  };

  render() {

    return (
      <div className={styles.wrapper}>
        <Switch>
          { this.renderAppRoutes() }
        </Switch>
        {/* SPA apps are rendered in this div */}
        <div id={scriptsContainerId} />
      </div>
    );
  }
}

import axios from 'axios';
import constants from 'utils/constants';
import loggerFactory from 'utils/logger';

const logger = loggerFactory('environmentService');

// TODO: create a global environmentsCache per org
// TODO: Should only call if last updated more than X minutes ago.
// The key is the orgId, value is the environments for that orgId.
let environmentsCache = {};

/**
 * Fetches the environments for the given orgId. Assumes that the user is a member of the org.
 */
const getEnvironments = async organizationId => {
  if (!organizationId) {
    throw new Error('getEnvironments must be called with an organizationId.');
  }
  if (environmentsCache[organizationId]) {

    return Promise.resolve(environmentsCache[organizationId]);
  }
  const resp = await axios.get(constants.ENVIRONMENTS_URL(organizationId));
  // Note: The CS resp has env in data i.e. { data: [ environments ] }
  // Note also that we only cache environments for one org at a time.
  environmentsCache = {};
  environmentsCache[organizationId] = resp.data.data;

  return environmentsCache[organizationId];
};

const environmentService = {
  getEnvironments
};

export default environmentService;

import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ShellContainer } from 'components';

/**
 * Root of the app, all top level dependencies should go here.
 */
export default class MainApp extends Component {
  render() {
    return (
      <BrowserRouter>
        <ShellContainer />
      </BrowserRouter>
    );
  }
}

import axios from 'axios';
import spaContext from 'utils/spaContext';
import loggerUtil from 'utils/logger';
import CONSTANTS from 'utils/constants';

const logger = loggerUtil('Axios');

/**
 * Adds the accessToken on requests
 */
const addAuthorizationTokenToRequest = config => {
  const { headers } = config;
  // Don't add the bearer token for profile & logout due to bug: https://www.mulesoft.org/jira/browse/CS-5158
  // If the token is used in /logout, the session will still be valid.
  // If the token is used in /profile, an accessToken can be used even if the session expired.
  const shouldAddBearerToken = ![CONSTANTS.LOGOUT_URL, CONSTANTS.PROFILE_URL].includes(config.url);
  if (shouldAddBearerToken) {

    headers.Authorization = `Bearer ${spaContext.accessToken}`;
  }
  return config;
};

/**
 * Intercept error response and handles 401 requests
 */
const handleUnauthorizedCalls = (error, handleUnauthorized) => {


  const isUnauthorized = error.response.status === 401;
  if (isUnauthorized) {
    // Callback from the shell container to redirect to the signin page.
    handleUnauthorized();
  }
  return Promise.reject(error);
};

/**
 * Configures axios global defaults, and so should be called only once at the
 * root of the shell app.
 *
 * @return {AxiosRequestConfig} The new axios default configuration.
 */
const configureAxios = handleUnauthorized => {
  axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';
  axios.defaults.headers['Content-Type'] = 'application/json';

  // Add a request interceptor
  axios.interceptors.request.use(
    addAuthorizationTokenToRequest,
    error => {

      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    res => {

      return res;
    },
    error => handleUnauthorizedCalls(error, handleUnauthorized));
};

export default configureAxios;

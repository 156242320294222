import accountService from 'services/accountService';
import environmentService from 'services/environmentService';
import loggerUtil from 'utils/logger';

const logger = loggerUtil('DeepLinkService');

/**
 * Determine the appropriate deep link action to take based on the requestedOrgId in the URL.
 * Note: The deep link always wins over the profile's activeOrgId.
 * @param requestedOrgId - the requestedOrgId from the url
 * @param profile - {Profile} used to get the activeOrgId and determine if the requestedOrgId exists.
 * @return {string} - the actual requestedOrgId to set in the path
 */
const computeOrgId = (requestedOrgId, profile) => {
  const orgIsValid = requestedOrgId && profile.orgExists(requestedOrgId);
  if (orgIsValid) {

    return requestedOrgId;
  }
  const activeOrgId = profile.getActiveOrgId();

  return activeOrgId;
};

/**
 * @param requestedEnvId - the requestedEnvId from the url
 * @param profile - {Profile}
 * @return {string} - the envId to set in the path
 */
const computeEnvId = async (requestedEnvId, profile) => {
  const environments = await environmentService.getEnvironments(profile.getActiveOrgId());
  const envIsValid = requestedEnvId && (environments && environments.some(env => env.id === requestedEnvId));
  if (envIsValid) {
    // For simplicity, always set the activeEnvId
    profile.setActiveEnvId(requestedEnvId);

    return Promise.resolve(requestedEnvId);
  }
  const activeEnvId = profile.getActiveEnvId(environments);

  return activeEnvId;
};

/**
 * Constructs a deep link using the user's active organization id and active environment id.
 *
 * There can be three types of deep links:
 * 1. Deep links that contain just the app's name.
 * 2. Deep links that contain the app's name and the active organization id.
 * 3. Deep links that contain the app's name, the active organization id, and the active environment id.
 *
 * @param appPath - the base path for the App, e.g. /mq/
 * @param orgId - valid orgId for the deep link.
 * @param envId - valid envId for the deep link.
 *
 * @return {string} - a URL path deep link, e.g. /mq/, /mq/organizations/ORG_ID/, or /mq/organizations/ORG_ID/environments/ENV_ID.
 */
const buildDeepLink = (appPath, orgId, envId) => {
  if (!orgId) {
    return appPath;
  }
  const orgIdPath = `organizations/${orgId}/`;
  const envIdPath = envId ? `environments/${envId}/` : '';
  const resultPath = `${appPath}${orgIdPath}${envIdPath}`;

  return resultPath;
};

/**
 * @param location {object} - the location with pathname.
 * @return {object} - an object with the orgId and envId from the URL pathname, assuming the url is in SPA deep link format.
 */
const getDeepLinkParams = location => {
  // Expected format: /appName/organizations/ORGID/environments/ENVID.
  const [, appName, orgs, orgId, envs, envId] = location.pathname.split('/'); // eslint-disable-line
  return {
    orgId,
    envId
  };
};

/**
 * Determines the rendering action depending on the path URL and what kind of deep link the app needs
 * (orgSwitching and envSwitching, orgSwitching, or just the app's path).
 * @return {Promise} object - object containing an updatedProfile and correctedPath
 * correctedPath: the corrected deep link path (e.g. when orgId or requestedEnvId need to be changed)
 * updatedProfile: the original profile or the new one after an org switch.
 */
const executeDeepLink = async (appPath, profile, needsOrg, requestedOrgId, needsEnv, requestedEnvId) => {
  const result = {
    updatedProfile: profile,
    correctedPath: appPath
  };
  if (!needsOrg) {
    return result;
  }
  const computedOrgId = computeOrgId(requestedOrgId, profile);
  // Changing the active organization causes the user's profile to have a set of permissions for the new org id.
  // This means we have to re-fetch the profile and re-render the UI with the new set of app permissions.
  const shouldUpdateActiveOrganization = computedOrgId !== profile.getActiveOrgId();
  if (shouldUpdateActiveOrganization) {
    result.updatedProfile = await accountService.updateActiveOrganization(profile.id, computedOrgId);
  }
  if (!needsEnv) {

    result.correctedPath = buildDeepLink(appPath, computedOrgId);
    return result;
  }
  // If the org can be rendered correctly with no further action (i.e. it is the user's active org) -> env render action.
  // Always handle org actions first to have the most up to date profile (with updated active org id) to get environments.
  const computedEnvId = await computeEnvId(requestedEnvId, result.updatedProfile);
  result.correctedPath = buildDeepLink(appPath, computedOrgId, computedEnvId);
  return result;
};

export {
  executeDeepLink,
  getDeepLinkParams
};
